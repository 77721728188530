.coming-soon-page {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video {
  width: 100%; /* Half size of the video */
  height: auto;
  object-fit: contain;
  z-index: 0;
  display: block;
  margin: 0 auto;
}

.content-container {
  position: absolute;
  bottom: 0;
  right: 0; /* Changed from left to right */
  z-index: 1;
  width: 100%; /* Half width for content */
  padding: 2rem;
  color: white;
  box-sizing: border-box;
  text-align: left; /* Align text to the left within the container */
}

.info-section {
  max-width: 500px;
  margin-right: 2rem; /* Changed from margin-left to margin-right */
  margin-left: auto; /* Push content to the right */
}

.info-wrapper {
  padding: 0;
}

.info-text {
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.contact-info {
  margin-top: 1.5rem;
}

.contact-info p {
  margin: 0.25rem 0;
  font-size: 1.2rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
}

.title-section {
  flex: 1;
  max-width: 500px;
}

.title {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
  position: relative;
  letter-spacing: -0.02em;
}

/* Add holographic/rainbow effect to the title */
.title:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 255, 0.5),
    rgba(255, 0, 255, 0.5),
    rgba(0, 255, 255, 0.5)
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  filter: blur(8px);
  text-shadow: none;
  animation: rainbow 6s ease infinite;
}

.email-link {
  color: #00ccff;
  text-decoration: none;
  transition: color 0.2s ease, text-decoration 0.2s ease;
  position: relative;
}

.email-link:focus {
  outline: none;
}

/* Add a subtle animated underline effect */
.email-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #00eeff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.email-link:hover::after {
  transform: scaleX(1);
}

@media (max-width: 992px) {
  .content-container {
    width: 100%;
    right: 0;
    text-align: center;
    padding: 1.5rem;
  }

  .info-section {
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .background-video {
    width: 75%; /* A bit larger on smaller screens */
  }

  .info-text {
    font-size: 1rem;
  }

  .contact-info p {
    font-size: 0.875rem;
  }
}
